import {
	Input,
	Spin,
} from "antd";
import cc from "classcat";
import {
	ChangeEvent,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import useApi from "../../../api";
import { ReactComponent as CopyOutlined } from "../../../assets/icons/copyOutlined.svg";
import { ReactComponent as DotFilled } from "../../../assets/icons/dotFilled.svg";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "../../../assets/icons/rightOutlined.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailLinkColumn from "../../../components/Column/RetailLinkColumn";
import RetailNameColumn from "../../../components/Column/RetailNameColumn";
import Empty from "../../../components/Empty";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import VastPlayer from "../../../components/Player/VastPlayer";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import useTableFetch from "../../../hooks/useTableFetch";
import { PolicyStatus } from "../../../utils/types";
import cm from "./style.module.scss";

const InspectPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { data, isLoading, pageSize, page, changePage, changePageSize } =
    useTableFetch("creatives", false, {}, undefined, [
      {
        key: "policy_status",
        op: "eq",
        value: "PENDING",
      },
    ]);

  const [current, setCurrent] = useState(0);

  const [allCreatives, setAllCreatives] = useState<{
    records: any[];
    total: number;
  }>({
    records: [],
    total: 0,
  });

  useEffect(() => {
    if (Array.isArray(data?.data?.records)) {
      setAllCreatives((prevCreatives) => ({
        records: [...prevCreatives.records, ...data?.data?.records],
        total: data?.data.total_records,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (data?.data.total_records) changePageSize(1, data.data.total_records);
  }, [changePageSize, data?.data.total_records]);
  const [rejectModalStates, setRejectModalStates] = useState({
    visible: false,
    note: "",
    record: {},
  });

  const notLastCard = current !== allCreatives.total - 1;

  const increment = () => {
    notLastCard && setCurrent((curr) => curr + 1);

    const comparablePageSize = page * pageSize - 1;

    if (current === comparablePageSize) changePage(page + 1);
  };

  const decrement = () => current !== 0 && setCurrent((curr) => curr - 1);

  const updateValue = async (data: any) => {
    const response = await api.patch(`creatives/${data.id}`, {
      policy_status: data.updated_status,
      review_note:
        rejectModalStates.note !== "" ? rejectModalStates.note : null,
    });
    return response;
  };

  const { mutateAsync, isLoading: mutationLoading } = useMutation(updateValue);

  const changeValue = async (data: any, status: PolicyStatus) => {
    try {
      const createdData = { ...data, updated_status: status };
      await mutateAsync(createdData).then(() => {
        setAllCreatives((prev) => ({
          records: prev.records.filter((item) => item.id !== createdData.id),
          total: prev.total - 1,
        }));
        if (!notLastCard) decrement();
      });
    } catch (err: any) {
      console.error(err);
    }
  };

  const onRejectOk = () => {
    changeValue(rejectModalStates.record, "REJECTED");
    setRejectModalStates({
      visible: false,
      note: "",
      record: {},
    });
  };

  const onRejectCancel = () => {
    setRejectModalStates({
      visible: false,
      note: "",
      record: {},
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRejectModalStates({
      ...rejectModalStates,
      note: e.target.value,
    });
  };

  const handleReject = (record: any) => {
    setRejectModalStates({
      visible: true,
      record,
      note: "",
    });
  };

  const copyTextToClipboard = (item: string) =>
    navigator.clipboard.writeText(item);

  return (
    <RetailPageContainer>
      <section className={cm.wrapper}>
        {allCreatives?.records?.length === 0 ? (
          <div className={cm.emptyContainer}>
            <Empty type="review" />
          </div>
        ) : (
          <div className={cm.container}>
            <Spin spinning={isLoading || mutationLoading} />
            {allCreatives?.records?.map((record: any, index: number) => (
              <div
                className={cc([cm.card, index === current ? cm.visible : ""])}
                key={record?.id}
              >
                <RetailTitle level={5} className={cm.title}>
                  {record?.creative_name}
                </RetailTitle>
                <div className={cm.imgContainer}>
                  {record?.format === "VIDEO" ? (
                    <VastPlayer
                      vastURL={record?.vast_tag || ""}
                      type="inspect"
                      id={record?.id}
                    />
                  ) : (
                    <img src={record?.image_url} alt={record?.creative_name} />
                  )}
                </div>
                {record?.vast_tag && (
                  <div className={cm.vastTag}>
                    <RetailText
                      weight="medium"
                      size="xs"
                      className={cm.vastTagText}
                    >
                      {t("pages.admin.inspect.vastTag")}
                    </RetailText>
                    <a
                      href={record?.vast_tag}
                      target="_blank"
                      rel="noreferrer"
                      className={cc([cm.link, cm.vastTagLink])}
                    >
                      {record?.vast_tag}
                    </a>
                  </div>
                )}
                <div className={cm.text}>
                  <div>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      {t("pages.admin.inspect.campaign")}
                    </RetailText>
                    <RetailLinkColumn
                      to={`/campaign/${record?.campaign_id}?adv=${record?.advertiser_id}`}
                      value={record?.campaign_name}
                      state={{ breadcrumb: true, breadcrumbType: "campaign" }}
                    />
                  </div>
                  <div>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      {t("pages.admin.inspect.advertiser")}
                    </RetailText>
                    <RetailNameColumn
                      to={`/campaigns?adv=${record?.advertiser_id}`}
                      value={`${record?.advertiser_name || "-"} (${
                        record?.advertiser_id
                      })`}
                    />
                  </div>
                </div>

                <div className={cc(["flex", cm.urlContainer])}>
                  <RetailText
                    family="poppins"
                    weight="medium"
                    size="xs"
                    className={cm.grayText}
                  >
                    {t("pages.admin.inspect.url")}
                  </RetailText>
                  <a
                    href={record?.redirect_url}
                    className={cm.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {record?.redirect_url}
                  </a>
                  <CopyOutlined
                    onClick={() => copyTextToClipboard(record?.redirect_url)}
                  />
                </div>

                <div className={cc(["flex", cm.sizeContainer])}>
                  <div className="flex">
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      {t("pages.admin.inspect.size")}
                    </RetailText>
                    <RetailText family="poppins" weight="medium" size="xs">
                      {record?.size}
                    </RetailText>
                  </div>

                  <DotFilled />

                  <div className="flex">
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      {t("pages.admin.inspect.status")}
                    </RetailText>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={
                        record?.policy_status
                          ? cm[record?.policy_status.toLowerCase()]
                          : ""
                      }
                    >
                      {record?.policy_status
                        ? t(
                            `pages.admin.creatives.${record?.policy_status.toLowerCase()}`
                          )
                        : "-"}
                    </RetailText>
                  </div>
                </div>
                {(record?.categories || record?.keywords) && (
                  <div className={cc(["flex", cm.infoContainer])}>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      {record?.categories
                        ? t("pages.admin.inspect.categories")
                        : t("pages.admin.inspect.keywords")}
                    </RetailText>
                    {(record?.categories &&
                      record?.categories.map((item: string) => (
                        <RetailText family="poppins" weight="medium" size="xs">
                          {item}
                        </RetailText>
                      ))) ||
                      (record?.keywords &&
                        record?.keywords.map((item: string) => (
                          <RetailText
                            family="poppins"
                            weight="medium"
                            size="xs"
                          >
                            {item}
                          </RetailText>
                        )))}
                  </div>
                )}

                <div className={cc(["flex", cm.btnContainer])}>
                  <RetailMainButton
                    hasBackground={false}
                    className={cc(["flex", cm.btn, cm.rejectBtn])}
                    onClick={() => handleReject(record)}
                  >
                    {t("common.reject")}
                  </RetailMainButton>

                  <RetailMainButton
                    hasBackground
                    className={cc(["flex", cm.btn, cm.okBtn])}
                    onClick={() => changeValue(record, "ACCEPTED")}
                  >
                    {t("common.accept")}
                  </RetailMainButton>
                </div>
              </div>
            ))}
            {!isLoading &&
              (allCreatives?.records?.length > 0 ||
                allCreatives?.total > 0) && (
                <div className={cc(["flex", cm.barContainer])}>
                  {current > 0 && (
                    <div
                      onClick={decrement}
                      className={cc(["flex", cm.barBtn])}
                    >
                      <LeftOutlined />
                    </div>
                  )}
                  <div className={cc(["flex", cm.bar])}>
                    <RetailText family="poppins" weight="medium" size="xs">
                      {current + 1}
                    </RetailText>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      /
                    </RetailText>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      {allCreatives?.total}
                    </RetailText>
                  </div>
                  {notLastCard && (
                    <div
                      onClick={increment}
                      className={cc(["flex", cm.barBtn])}
                    >
                      <RightOutlined />
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </section>

      <CampaignDetailsModal
        type="REJECT_CREATIVE"
        subtitle={t("pages.admin.creatives.rejectSubtitle")}
        visible={rejectModalStates.visible}
        onOk={onRejectOk}
        onCancel={onRejectCancel}
      >
        <RetailFormInput
          isFocused={rejectModalStates.note !== ""}
          name="note"
          label={t("pages.admin.creatives.note")}
          className="floating"
        >
          <Input.TextArea
            className={cc(["floating", cm.textarea])}
            onChange={handleInputChange}
            value={rejectModalStates.note}
          />
        </RetailFormInput>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default InspectPage;
